import { ApolloError } from "@apollo/client";
import { Device } from "@capacitor/device";
import * as Sentry from "@sentry/react";
import { matchPath } from "react-router-dom";

import { PathMatch } from "components/routing/utils";
import { getPlatform, isNative } from "utils/platform";
import env from "utils/processEnv";

import { history } from "./InstrumentedRouter";
import { nativeVersionInfo, versionInfo } from "./version";

(async () => {
  const { fullVersionString } = versionInfo;
  Sentry.setTag("client_version", fullVersionString);

  const platform = getPlatform();
  Sentry.setTags({
    client_platform: platform,
  });

  if (isNative()) {
    // The OS version captured by Sentry is inaccurate for MacOS because MacOS
    // does not properly report its version via the User Agent string
    const { osVersion } = await Device.getInfo();

    const { version, buildNumber } = await nativeVersionInfo;
    Sentry.setTags({
      client_native_build_number: buildNumber,
      client_native_version: version,
      client_os_version: osVersion,
    });
  }

  Sentry.getCurrentScope().addEventProcessor(event => ({
    ...event,
    release: fullVersionString,
  }));
})();

const {
  glueApiUrl,
  glueEnv: environment,
  sentryDsn: dsn,
  sentryTracesSampleRate,
  sentryReplaysSampleRate,
} = env;

if (dsn) {
  const routes = Object.values(PathMatch);
  Sentry.init({
    dsn,
    environment,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
        maskAllInputs: true,
        minReplayDuration: 5 * 1000, // 5 seconds
        maxReplayDuration: 5 * 60 * 1000, // 5 minutes
      }),
    ],
    maxBreadcrumbs: 5,
    replaysSessionSampleRate: sentryReplaysSampleRate,
    replaysOnErrorSampleRate: 0, // buffered recording is too expensive
    tracePropagationTargets: [new URL(glueApiUrl).origin],
    tracesSampleRate: sentryTracesSampleRate,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error instanceof ApolloError) {
        if (error.message.match(/Fetch without auth token/i)) {
          event.level = "warning";
        }

        if (error.networkError?.cause) {
          event.extra = {
            batched_operations: error.networkError.cause,
          };
        }
      }

      return event;
    },
  });
}
